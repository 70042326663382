
import WidgetEmbed from '@/components/shared/WidgetEmbed.vue';
import { LanguageKey, Widget } from '@govflanders/mbp-admin-panel-shared';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import BrandingSection from '@/components/shared/forms/BrandingSection.vue';
import ImageUpload from '@/components/shared/forms/ImageUpload.vue';
import UrlList from '@/components/shared/forms/UrlList.vue';
import UuidField from '@/components/shared/forms/UuidField.vue';
import FooterLinkList from '@/components/shared/forms/footer/FooterLinkList.vue';
import LanguageLinkList from '@/components/shared/forms/footer/LanguageLinkList.vue';
import SiteInfo from '@/components/shared/forms/footer/SiteInfo.vue';
import WidgetPreviewData from '@/components/widgets/WidgetPreviewData.vue';
import { ConfigValueName } from '@govflanders/mbp-admin-panel-shared';
import MultiLanguageImageUpload from '../shared/MultiLanguageImageUpload.vue';
import MultiLanguageInput from '../shared/MultiLanguageInput.vue';
import MultiLanguageSection from '../shared/forms/MultiLanguageSection.vue';
import {debounce} from 'lodash';

@Component({
  components: {
    WidgetEmbed,
    UuidField,
    UrlList,
    SiteInfo,
    FooterLinkList,
    LanguageLinkList,
    ImageUpload,
    WidgetPreviewData,
    BrandingSection,
    MultiLanguageSection,
    MultiLanguageInput,
    MultiLanguageImageUpload,
  },
})
export default class WidgetFormFooter extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  public value: Widget;

  @Getter('config/get')
  private getConfig: (value: ConfigValueName) => string;

  public get widgetsDirectory(): string {
    return this.getConfig(ConfigValueName.CLOUDINARY_FILE_PREFIX);
  }

  @Getter('app/env')
  public env: string;

  @Getter('organisationSearch/isLoading')
  public organisationNamesLoading: boolean;

  @Getter('organisationSearch/results')
  public organisationNames: string[];

  public loadOrganisationNames = debounce(
      query => this.$store.dispatch('organisationSearch/search', { query }),
      300,
  );

  public get activeTab(): number {
    if (!this.value.host || !this.value.host.type || this.value.host.type === this.TEXT_HOST_TYPE) {
      return 0;
    }
    return 1;
  }
  public readonly TEXT_HOST_TYPE = 'text';
  public readonly IMAGE_HOST_TYPE = 'image';
  public readonly DEFAULT_UMBRELLA_SRC =
    'https://assets.vlaanderen.be/image/upload/widgets/vlaanderen-logo.svg';

  public addHost(type) {
    this.value.host = { type };
    this.updateHostOfLanguages({});
  }

  public handleRemoveEntityLogo() {
    this.value.host = null;
    this.updateHostOfLanguages(null);
  }

  public handleRemoveUmbrella() {
    this.value.umbrella = null;
  }

  public handleAddUmbrella() {
    this.value.umbrella = {
      src: this.DEFAULT_UMBRELLA_SRC,
      alt: 'Vlaanderen logo',
    };
  }

  public migrateBranding() {
    this.$emit('migrate-branding');
  }

  private updateHostOfLanguages(value) {
    if (!this.value.i18n) {
      return;
    }
    const languages = (Object.keys(this.value.i18n.translations) || []) as LanguageKey[];
    languages.forEach((language) => {
      this.$set(this.value.i18n.translations[language], 'host', value ? { ...value } : null);
    });
  }
}
