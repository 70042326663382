
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  ELoketExtension,
  FeatureToggleName,
  PluginTypeId,
  Widget,
  WidgetBranding,
} from '@govflanders/mbp-admin-panel-shared';
import WidgetEmbed from '@/components/shared/WidgetEmbed.vue';
import WidgetPreviewData from '@/components/widgets/WidgetPreviewData.vue';

import UrlList from '@/components/shared/forms/UrlList.vue';
import UrlListWithLabelInput from '@/components/shared/forms/UrlListWithLabelInput.vue';
import StringList from '@/components/shared/forms/StringList.vue';
import UuidField from '@/components/shared/forms/UuidField.vue';
import ColorField from '@/components/shared/forms/ColorField.vue';
import { Getter } from 'vuex-class';
import { getHeaderExtensionTemplate } from '@/utility/widgets';
import ContactOptionSelector from '@/components/shared/ContactOptionSelector.vue';
import BrandingSection from '../shared/forms/BrandingSection.vue';
import Cobrowse from '../cobrowse/Cobrowse.vue';
import MultiLanguageSection from '../shared/forms/MultiLanguageSection.vue';
import MultiLanguageInput from '../shared/MultiLanguageInput.vue';
import MultiLanguageContactOptionSelector from '../shared/MultiLanguageContactOptionSelector.vue';
import Alert from '@/components/alert/Alert.vue';
import { debounce } from 'lodash';

@Component({
  components: {
    Alert,
    ContactOptionSelector,
    BrandingSection,
    WidgetEmbed,
    UrlList,
    UuidField,
    ColorField,
    StringList,
    UrlListWithLabelInput,
    WidgetPreviewData,
    Cobrowse,
    MultiLanguageSection,
    MultiLanguageInput,
    MultiLanguageContactOptionSelector,
  },
})
export default class WidgetFormHeader extends Vue {
  @Prop({
    type: Object as PropType<Widget>,
    required: true,
  })
  public value: Widget;

  @Getter('app/env')
  public env: string;

  @Getter('organisationSearch/isLoading')
  public organisationNamesLoading: boolean;

  @Getter('organisationSearch/results')
  public organisationNames: string[];

  public loadOrganisationNames = debounce(
    (query) => this.$store.dispatch('organisationSearch/search', { query }),
    300,
  );

  public eloketHeaderTypes = ['none', 'breadcrumbs', 'navigationItems'];
  public mainCapacityCodeCounters = ['BUR', 'VER', 'EA'];
  @Getter('featureToggles/isFeatureAvailable')
  public isFeatureAvailable: (name: FeatureToggleName) => boolean;
  private readonly CONTACT_KEY = 'contact';

  public get contactCapiKey() {
    return this.contactExtension?.defaultContext;
  }

  public set contactCapiKey(value) {
    this.contactExtension.defaultContext = value;
  }

  public get contactExtension() {
    return this.getExtension('contact');
  }

  public get citizenProfileExtension() {
    return this.getExtension('citizen_profile');
  }

  public get eloketExtension(): ELoketExtension {
    return this.getExtension('eloket') as ELoketExtension;
  }

  public get hasSessionSupport() {
    return (
      this.citizenProfileExtension &&
      this.citizenProfileExtension.session &&
      this.citizenProfileExtension.session.application &&
      this.citizenProfileExtension.session.application.sessionSupport
    );
  }

  public get googleCheckbox() {
    return !!this.getExtension('tracker');
  }

  public set googleCheckbox(enable) {
    this.enableExtension('tracker', enable);
  }

  public get searchCheckbox() {
    return !!this.getExtension('search');
  }

  public set searchCheckbox(enable: boolean) {
    this.enableExtension('search', enable);
  }

  public get breadcrumbCheckbox() {
    return !!this.getExtension('breadcrumb');
  }

  public set breadcrumbCheckbox(enable: boolean) {
    this.enableExtension('breadcrumb', enable);
  }

  public get eloketCheckbox() {
    return !!this.getExtension('eloket');
  }

  public set eloketCheckbox(enable: boolean) {
    this.enableExtension('eloket', enable);
  }

  public get mbpCheckbox() {
    return !!this.getExtension('citizen_profile');
  }

  public set mbpCheckbox(enable: boolean) {
    this.enableExtension('citizen_profile', enable);
  }

  mounted() {
    // use application name as fallback for default language
    const defaultLanguage = this.value.i18n.default;
    const applicationName = this.citizenProfileExtension?.application?.name || '';
    const i18nApplication =
      this.citizenProfileExtension?.i18n?.translations[defaultLanguage]?.application;

    if (i18nApplication && !i18nApplication?.name) {
      this.$set(i18nApplication, 'name', applicationName);
    }
  }

  created() {
    if (
      typeof this.value.branding === 'object' &&
      this.value.branding &&
      this.value.branding.host &&
      this.value.branding.host.logo &&
      !this.value.branding.host.logo.src
    ) {
      // remove logo from host to prevent issues (not used alot)
      delete this.value.branding.host.logo;
    }

    if (this.citizenProfileExtension) {
      const application = this.citizenProfileExtension.application;
      const name = application ? application.name : '';
      const mainCapacityCode = application ? application.mainCapacityCode : 'BUR';
      const isMainCapacity = application && !!application.isMainCapacity;

      this.$set(this.citizenProfileExtension, 'application', {
        name,
        isMainCapacity,
        mainCapacityCode,
      });
    }

    if (this.contactExtension && !this.contactExtension.cobrowse) {
      this.$set(this.contactExtension, 'cobrowse', {
        isEnabled: false,
        channels: ['link-mobile'],
        licenseKey: '',
      });
    }

    if (!this.contactExtension.cobrowse?.consent) {
      this.$set(this.contactExtension.cobrowse, 'consent', { title: '', htmlContent: '' });
    }
  }

  public getExtension(key: PluginTypeId) {
    return this.value.extensions.find((el) => el && el.pluginTypeId === key);
  }

  public setBranding(value: WidgetBranding | string) {
    if (typeof value === 'string') {
      this.value.brandingId = value;
    }

    if (this.value.branding) {
      delete this.value.branding;
    }
  }

  public removeExtension(key: PluginTypeId) {
    this.value.extensions = [
      ...this.value.extensions.filter((el) => el && el.pluginTypeId !== key),
    ];
  }

  public handleChangeCapiKey(value: string): void {
    if (value && !this.contactCapiKey) {
      this.value.extensions.push({
        ...getHeaderExtensionTemplate(this.env, this.CONTACT_KEY),
        cobrowse: {
          isEnabled: false,
          channels: ['link-mobile'],
          licenseKey: '',
          consent: {
            title: '',
            htmlContent: '',
          },
        },
        defaultContext: value,
      });
    } else if (value) {
      this.contactCapiKey = value;
    } else {
      this.removeExtension('contact');
    }
  }

  public enableExtension(key: PluginTypeId, enable: boolean) {
    if (enable) {
      this.value.extensions.push(getHeaderExtensionTemplate(this.env, key));
    } else {
      this.removeExtension(key);
    }

    this.$emit('input', this.value);
  }

  public migrateBranding() {
    this.$emit('migrate-branding');
  }

  public handleBrandingChange(id) {
    if (id && this.value.branding) {
      delete this.value.branding;
    }
  }
}
