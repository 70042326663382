import { Schema, Templates } from '@/json';
import { PluginTypeId, Widget, WidgetExtension } from '@govflanders/mbp-admin-panel-shared';
import { cloneDeep } from 'lodash';

/**
 * Get the widget configuration schema.
 *
 * @param {string} type
 *   Type which holds the plugin module and type ID.
 *
 * @return {object}
 *   JSON schema for given type.
 */
export function getWidgetConfigSchema(type: string): object {
  // Evaluate the type of widget config.
  switch (type) {
    case '@govflanders/vl-widget-plugin-global-header:global_header':
      return Schema.GlobalHeader;

    case '@govflanders/vl-widget-plugin-global-footer:global_footer':
      return Schema.GlobalFooter;

    default:
      return Schema.Default;
  }
}

/**
 * Get the widget configuration template.
 *
 * @param {string} type
 *   Type which holds the plugin module and type ID.
 *
 * @return {object}
 *   Widget object which represents a template.
 */
export function getWidgetConfigTemplate(type: string): { [key: string]: Widget } {
  // Evaluate the type of widget config.
  switch (type) {
    case '@govflanders/vl-widget-plugin-global-header:global_header':
      return Templates.GlobalHeader as { [key: string]: Widget };

    case '@govflanders/vl-widget-plugin-global-footer:global_footer':
      return Templates.GlobalFooter;

    default:
      return Templates.Default;
  }
}

/**
 * Resolve widget config schema.
 *
 * @param {string} type
 *   Type which holds the plugin module and type ID.
 * @param {string} env
 *   Environment for which template should be retrieved.
 *
 * @return {object}
 *   JSON schema and templates for given type.
 */
export function getWidgetConfigSchemaAndTemplate(
  type: string,
  env: string,
): { schema: object; value: Widget } {
  // Get the widget config schema and template.
  const schema = getWidgetConfigSchema(type);
  const template = getWidgetConfigTemplate(type);
  // Combine both schema and value.
  return {
    schema,
    value: cloneDeep(template[env] || {}),
  };
}

/**
 * Resolve widget extension config template.
 *
 * @param {string} pluginTypeId
 *   Type which holds the plugin module and type ID.
 * @param {string} env
 *   Environment for which template should be retrieved.
 *
 * @return {object}
 *   JSON schema and templates for given type.
 */
export function getHeaderExtensionTemplate(
  env: string,
  pluginTypeId: PluginTypeId,
): WidgetExtension {
  return cloneDeep(
    Templates.GlobalHeaderExtensions[env].find(el => el.pluginTypeId === pluginTypeId),
  );
}
