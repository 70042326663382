
import { Widget } from '@govflanders/mbp-admin-panel-shared';
import { Component, Prop, Vue } from 'vue-property-decorator';
import MultiLanguageInput from '@/components/shared/MultiLanguageInput.vue';

@Component({
  components: {
    MultiLanguageInput,
  },
})
export default class FooterLinkList extends Vue {
  @Prop({
    type: Array || null,
    required: true,
  })
  public value: { label: string; href: string; target?: string }[];

  @Prop({
    type: Object,
    required: true,
  })
  public base: Widget;

  public handleAddNavigationLink(): void {
    const navigationLink = { label: '', href: '', target: '_blank' };

    this.$set(
      this.base,
      'navigationLinks',
      this.base.navigationLinks ? [...this.base.navigationLinks, navigationLink] : [navigationLink],
    );

    // Translations also need to be added.
    Object.keys(this.base.i18n.translations).forEach(lang => {
      const languageTranslations = this.base.i18n.translations[lang];
      this.$set(
        languageTranslations,
        'navigationLinks',
        languageTranslations.navigationLinks
          ? [...languageTranslations.navigationLinks, { label: '' }]
          : [{ label: '' }],
      );
    });
  }

  public handleRemoveNavigationLink(i): void {
    this.$set(
      this.base,
      'navigationLinks',
      this.base.navigationLinks.filter((_, index) => index !== i),
    );

    // Translations also need to be removed.
    Object.keys(this.base.i18n.translations).forEach(lang => {
      const languageTranslations = this.base.i18n.translations[lang];
      this.$set(
        languageTranslations,
        'navigationLinks',
        languageTranslations.navigationLinks.filter((_, index) => index !== i),
      );
    });
  }
}
