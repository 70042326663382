import { Branding, Widget } from '@govflanders/mbp-admin-panel-shared';
import { cloneDeep } from 'lodash';
import { getBrandingTemplate } from '@/utility/branding';

export const cleanUpOldBrandingConfig = (value: Widget): Widget => {
  delete value.branding;
  delete value.umbrella;
  delete value.host;

  return value;
};

export const migrateBranding = (value: Widget, storeRef, translationRef, toastRef) => {
  const defaultBranding: Branding = cloneDeep(getBrandingTemplate());
  const newBranding: Branding = {
    ...defaultBranding,
    label: `Branding for ${value.id}`,
    level: value.branding.level,
    umbrella: {
      // Umbrella tekst (dus linkse tekst van links boven)
      header: value.branding.umbrella
        ? {
            label: value.branding.umbrella.label,
            href: value.branding.umbrella.href,
            target: '_blank',
          }
        : defaultBranding.umbrella.header,
      // Footer logo (links onder)
      footer: value.umbrella
        ? {
            src: value.umbrella.src,
            srcSet: value.umbrella.srcSet || [],
            alt: value.umbrella.alt,
          }
        : defaultBranding.umbrella.footer,
    },
    // Header host (enkel voor de header)
    host: value.branding.host ? {
      label: value.branding.host.label,
      href: value.branding.host.href,
      target: value.branding.host.target || '_blank',
      logo: value.branding.host.logo
        && {
            src: value.branding.host.logo.src,
            alt: value.branding.host.logo.alt,
            srcSet: value.branding.host.logo.srcSet || [],
          },
    }: defaultBranding.host,
    colors: value.branding.colors
      ? {
          primary: value.branding.colors.primary,
          functional: value.branding.colors.functional,
        }
      : defaultBranding.colors,
    // Footer host (rechts onder - kan tekst of logo zijn)
    logos: value.host
      ? {
          entity: value.host,
        }
      : defaultBranding.logos,
  };

  return new Promise(resolve =>
    storeRef
      .dispatch('branding/create', newBranding)
      .then(newBranding => {
        value = cleanUpOldBrandingConfig(value);
        value = { ...value, brandingId: newBranding.id };
        toastRef.success(translationRef('widgets.configform.brandingDeprecatedMigrated'));
        resolve(value);
      })
      .catch(err => {
        toastRef.error(translationRef('widgets.configform.brandingDeprecatedMigratedFailed'));
        console.log(err);
      }),
  );
};
