
import { Widget } from '@govflanders/mbp-admin-panel-shared';
import { Component, Prop, Vue } from 'vue-property-decorator';
import MultiLanguageInput from '@/components/shared/MultiLanguageInput.vue';

@Component({
  components: {
    MultiLanguageInput,
  },
})
export default class LanguageLinkList extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  private value: { langcode: string; label: string; href: string; target?: string }[];

  @Prop({
    type: Object,
    required: true,
  })
  public base: Widget;

  public handleAddLanguageLink(): void {
    const languageLink = { label: '', href: '', langcode: '', target: '_blank' };

    this.$set(
      this.base,
      'languageLinks',
      this.base.languageLinks ? [...this.base.languageLinks, languageLink] : [languageLink],
    );

    // Translations also need to be added.
    Object.keys(this.base.i18n.translations).forEach(lang => {
      const languageTranslations = this.base.i18n.translations[lang];
      this.$set(
        languageTranslations,
        'languageLinks',
        languageTranslations.languageLinks
          ? [...languageTranslations.languageLinks, { label: '' }]
          : [{ label: '' }],
      );
    });
  }

  public handleRemoveLanguageLink(i): void {
    this.$set(
      this.base,
      'languageLinks',
      this.base.languageLinks.filter((_, index) => index !== i),
    );

    // Translations also need to be removed.
    Object.keys(this.base.i18n.translations).forEach(lang => {
      const languageTranslations = this.base.i18n.translations[lang];
      this.$set(
        languageTranslations,
        'languageLinks',
        languageTranslations.languageLinks.filter((_, index) => index !== i),
      );
    });
  }
}
