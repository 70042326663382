
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WidgetBranding } from '@govflanders/mbp-admin-panel-shared';
import BrandingSelector from '../BrandingSelector.vue';
import ColorField from './ColorField.vue';

@Component({ components: { BrandingSelector, ColorField } })
export default class BrandingSection extends Vue {
  @Prop({
    type: [Object, String],
  })
  public value: string;

  @Prop({
    type: [Boolean],
  })
  public showDeprecationWarning: boolean;

  public setBranding(value: WidgetBranding | string): void {
    this.$emit('input', value);
  }

  public migrateBrandingConfig(): void {
    this.$emit('migrate-branding');
  }
}
