
import { WidgetCobrowse, WidgetExtension } from '@govflanders/mbp-admin-panel-shared';
import { Component, Prop, Vue } from 'vue-property-decorator';
import MultiLanguageInput from '@/components/shared/MultiLanguageInput.vue';

@Component({
  components: { MultiLanguageInput },
})
export default class Cobrowse extends Vue {
  @Prop({ type: Object, required: true })
  public value!: WidgetCobrowse;

  @Prop({
    type: Object,
    required: true,
  })
  public base: WidgetExtension;
}
