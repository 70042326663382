
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Branding } from '@govflanders/mbp-admin-panel-shared';
import { debounce } from 'lodash';

@Component
export default class BrandingSelector extends Vue {
  @Prop({
    type: String,
  })
  public value: string;

  @Prop({
    type: Boolean,
  })
  public validationError: boolean;

  public brandingOptionsLoading = false;
  public brandingOptions: Branding[] = [];

  public mounted() {
    if (!this.brandingOptions.length) {
      this.loadBrandingOptions(this.value);
    }
  }

  public get selectedBranding(): Branding | string {
    return (
      (this.brandingOptions && this.brandingOptions.find(option => option.id === this.value)) ||
      this.value
    );
  }

  public loadBrandingOptions = debounce(this.getBrandingOptionsForQuery, 300);

  public getBrandingOptionsForQuery(query: string): void {
    if (query) {
      this.brandingOptionsLoading = true;
      this.$store
        .dispatch('brandingSearch/search', { query })
        .then(result => (this.brandingOptions = result))
        .finally(() => (this.brandingOptionsLoading = false));
    }
  }

  public handleInput(selectedOption: Branding) {
    this.$emit('input', selectedOption.id);
  }
}
