
import { Component, Prop } from 'vue-property-decorator';
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderAction from '@/components/shared/PageHeaderAction.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';
import JsonEditor from '@/components/shared/forms/JsonEditor.vue';
import WidgetMetaData from '@/components/widgets/WidgetMetaData.vue';
import WidgetFormHeader from '@/components/widgets/WidgetFormHeader.vue';
import WidgetFormFooter from '@/components/widgets/WidgetFormFooter.vue';
import { Widget } from '@govflanders/mbp-admin-panel-shared';
import { getWidgetConfigSchemaAndTemplate } from '@/utility/widgets';
import { cloneDeep } from 'lodash';
import { Getter } from 'vuex-class';
import ValidationNotification from '@/components/shared/ValidationNotification.vue';
import PendingChangesGuard from '@/utility/forms/PendingChangesGuard.vue';
import { mixins } from 'vue-class-component';
import RoleGuard from '@/utility/auth/RoleGuard.vue';
import * as brandingMigrationHelper from './branding-migration-helper';
import { getContrastMessages } from '@/utility/branding';

@Component({
  components: {
    Anchor,
    PageHeader,
    PageHeaderAction,
    PageHeaderNavigationAction,
    JsonEditor,
    WidgetMetaData,
    WidgetFormHeader,
    WidgetFormFooter,
    ValidationNotification,
  },
})
export default class WidgetEdit extends mixins(PendingChangesGuard, RoleGuard) {
  /**
   * Holds the widget identifier.
   *
   * @var {string}
   */
  @Prop({
    type: String,
    required: true,
  })
  public id!: string;

  @Getter('app/env')
  public env: string;

  public value: Widget | null = null;
  public initialValue: Widget | null = null;
  public schema: object | null = null;
  public isLoading: boolean;
  public error = '';

  private _config: { schema: object; value: Widget };

  public get contrastMessages() {
    const primary = this.value?.branding?.colors?.primary;
    const functional = this.value?.branding?.colors?.functional;

    return getContrastMessages(primary, functional);
  }

  public async created() {
    this.isLoading = true;

    this.$store
      .dispatch('widget/get', this.id)
      .then((value) => {
        if (value) {
          this._config = getWidgetConfigSchemaAndTemplate(
            `${value.pluginModule}:${value.pluginTypeId}`,
            this.env,
          );

          this._config.value = value;

          if (this._config.value.extensions) {
            this._config.value.extensions = this._config.value.extensions.filter((el) => !!el);
          }

          this.schema = this._config.schema;
          this.value = JSON.parse(JSON.stringify(this._config.value));
          this.initialValue = JSON.parse(JSON.stringify(this._config.value));
        } else {
          this.value = null;
        }
        this.isLoading = false;
      })
      .catch((error) => {
        this.error = error;
        this.isLoading = false;
      });
  }

  public onSave() {
    if (this.value) {
      if (this.value.branding && this.value.brandingId) {
        this.value = brandingMigrationHelper.cleanUpOldBrandingConfig(this.value);
      }

      this.$store
        .dispatch('widget/update', this.value)
        .then((updatedValue) => {
          this.value = updatedValue;
          this.initialValue = cloneDeep(updatedValue);
          // TODO: Should provide translation support.
          this.$toast.success('Widget aanpassingen opgeslagen');
        })
        .catch((err) => {
          // TODO: Should provide translation support.
          this.$toast.error('Kon widget aanpassingen niet opslaan');
          console.log(err);
        });
    }
  }

  public onRevert() {
    this.value = this._config.value;
    this.$toast.success('Widget was reverted');
  }

  public migrateBranding() {
    brandingMigrationHelper
      .migrateBranding(this.value, this.$store, (key) => this.$t(key), this.$toast)
      .then((response) => (this.value = response));
  }

  private cleanUpOldBrandingConfig() {
    delete this.value.branding;
    delete this.value.umbrella;
    delete this.value.host;
  }
}
