
import MultiLanguageInput from '@/components/shared/MultiLanguageInput.vue';
import { Widget } from '@govflanders/mbp-admin-panel-shared';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    MultiLanguageInput,
  },
})
export default class SiteInfo extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  public value: {
    title: string;
    subTitleItems: { type: string; label: string; href: string; target: string }[];
  };

  @Prop({
    type: Object,
    required: true,
  })
  public base: Widget;

  public get textItemIndex() {
    return this.value?.subTitleItems.findIndex((el) => el.type === 'text');
  }

  public get linkItem() {
    return this.value?.subTitleItems.find((el) => el.type === 'link');
  }

  public handleAddIssuingEntity(): void {
    const siteInfo = this.base.siteInfo;

    const buildSubTitleItem = () => ({
      type: 'link',
      href: null,
      label: null,
      target: '_blank',
    });

    this.$set(
      siteInfo,
      'subTitleItems',
      siteInfo.subTitleItems
        ? [...siteInfo.subTitleItems, buildSubTitleItem()]
        : [buildSubTitleItem()],
    );

    // Translations also need to be added.
    Object.keys(this.base.i18n.translations).forEach((lang) => {
      this.$set(
        this.base.i18n.translations[lang].siteInfo,
        'subTitleItems',
        this.base.i18n.translations[lang].siteInfo.subTitleItems
          ? [...this.base.i18n.translations[lang].siteInfo.subTitleItems, buildSubTitleItem()]
          : [buildSubTitleItem()],
      );
    });
  }

  public handleRemoveIssuingEntity(item): void {
    this.$set(
      this.base.siteInfo,
      'subTitleItems',
      this.base.siteInfo.subTitleItems.filter((currentItem) => currentItem !== item),
    );

    // Translations also need to be removed.
    Object.keys(this.base.i18n.translations).forEach((lang) => {
      this.$set(
        this.base.i18n.translations[lang].siteInfo,
        'subTitleItems',
        this.base.i18n.translations[lang].siteInfo.subTitleItems.filter(
          (currentItem) => currentItem !== item,
        ),
      );
    });
  }
}
