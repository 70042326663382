
import { Component, Vue } from 'vue-property-decorator';
import { IpdcProductTheme } from '@govflanders/mbp-admin-panel-shared';
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderAction from '@/components/shared/PageHeaderAction.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';
import ThemeForm from '@/components/ipdc/ThemeForm.vue';
import { v4 as uuid } from 'uuid';

@Component({
  components: {
    ThemeForm,
    Anchor,
    PageHeader,
    PageHeaderAction,
    PageHeaderNavigationAction,
  },
})
export default class IpdcProductThemeAdd extends Vue {
  private value: IpdcProductTheme | null = null;

  private created() {
    this.value = {
      code: uuid(),
      name: null,
    };
  }

  public revert() {
    this.value = {
      code: null,
      name: null,
    };
  }

  public save() {
    this.$store
      .dispatch('ipdcProductTheme/create', this.value)
      .then(createdValue => {
        this.value = createdValue;
        this.$toast.success(this.$t('ipdc.mbp-theme.create.created'));
        this.$router.push({ name: 'ipdc.theme.edit', params: { id: createdValue.code } });
      })
      .catch(e => {
        console.error(e);
        this.$toast.error(this.$t('ipdc.mbp-theme.create.error'));
      });
  }
}
