
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';
import { ImportedWidget } from '@govflanders/mbp-admin-panel-shared';
import _ from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

/**
 * Widgets overview for use with Vue Router.
 */
@Component({
  components: {
    PageHeader,
    PageHeaderNavigationAction,
    Anchor,
  },
})
export default class WidgetImportOverview extends Vue {
  @Getter('widgetImport/isFailed')
  private isFailed!: boolean;
  @Getter('widgetImport/isLoading')
  private isLoading!: boolean;
  @Getter('widgetImport/isSuccess')
  private isSuccess!: boolean;
  @Getter('widgetImport/results')
  private results!: ImportedWidget[] | null;

  private get supportedWidgetTypes(): string[] {
    return [
      '@govflanders/vl-widget-plugin-global-header:global_header',
      '@govflanders/vl-widget-plugin-global-footer:global_footer',
    ];
  }

  /**
   * Internal storage for our dossier Name
   *
   * @var {string}
   */
  private internalDossierName = '';

  /**
   * Get the dossier Name
   *
   * @return {string}
   *   An object which holds the search query.
   */
  public get dossierName(): string {
    return this.internalDossierName;
  }

  /**
   * Update the current dossier Name.
   *
   * @param {string} value
   *   Value to be used as dossier Name.
   */
  public set dossierName(value: string) {
    if (value && value.length > 0 && value !== this.internalDossierName) {
      // Update our internal copy of the query.
      this.internalDossierName = value;
      // Update the router to include the updated query params.
      if (this.$route.query.dossierName !== value) {
        this.$router.push({
          name: 'widgets-overview',
          query: { dossierName: this.dossierName },
        });
      }
    }
  }

  /**
   * Execute get operation.
   *
   * @return {Promise<void>}
   *   A promise to perform a get operation.
   */
  public async getWidgetsForRequest(): Promise<void> {
    // Dispatch get operation for given dossier Name.
    await this.$store.dispatch('widgetImport/execute', this.dossierName);
  }

  /**
   * Watch for changes to the dossierName so we can refresh the widgets list.
   */
  @Watch('internalDossierName')
  private onDossierNameChange() {
    // Perform get operation as id has changed.
    this.getWidgetsForRequest();
  }

  /**
   * React to router navigation being performed to keep dossierName up to date.
   */
  private beforeRouteEnter(to, from, next) {
    // Allow navigation but await success before updating the
    // query params.
    next((vm: this) => {
      // Update the query params to match route query params.
      vm.dossierName = _(to.query).omitBy(_.isUndefined).omitBy(_.isNull).value().dossierName;
    });
  }

  /**
   * React to router navigation being performed to keep search query up to date.
   */
  private beforeRouteUpdate(to, from, next) {
    // Update the query params to match route query params.
    this.dossierName = _(to.query).omitBy(_.isUndefined).omitBy(_.isNull).value().dossierName;
    // Allow route to be updated.
    next();
  }
}
