
import CertificateForm from '@/components/certificates/CertificateForm.vue';
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderAction from '@/components/shared/PageHeaderAction.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';
import ValidationNotification from '@/components/shared/ValidationNotification.vue';
import { getCertificateTemplate } from '@/utility/certificates';
import PendingChangesGuard from '@/utility/forms/PendingChangesGuard.vue';
import { Certificate } from '@govflanders/mbp-admin-panel-shared';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  components: {
    CertificateForm,
    Anchor,
    PageHeader,
    PageHeaderAction,
    PageHeaderNavigationAction,
    ValidationNotification,
  },
})
export default class CertificateAdd extends mixins(PendingChangesGuard) {
  value: Certificate | null = null;
  initialValue: Certificate = null;

  private created() {
    this.value = getCertificateTemplate();
    this.initialValue = getCertificateTemplate();
  }

  public revert() {
    this.value = getCertificateTemplate();
  }

  public save() {
    this.$store
      .dispatch('certificate/create', this.value)
      .then((createdValue) => {
        this.initialValue = createdValue;
        this.value = createdValue;
        this.$toast.success(this.$t('certificates.create.created'));
        this.$router.push({ name: 'certificates.edit', params: { id: createdValue.id } });
      })
      .catch((e) => {
        console.error(e);
        this.$toast.error(this.$t('certificates.create.error'));
      });
  }
}
