
import { WidgetExtension } from '@govflanders/mbp-admin-panel-shared';
import { Component, Prop, Vue } from 'vue-property-decorator';
import MultiLanguageInput from '../MultiLanguageInput.vue';

@Component({
  components: {
    MultiLanguageInput,
  },
})
export default class UrlListWithLabelInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  public base: WidgetExtension;

  public handleAddNavigationLink(): void {
    const { label, href } = { label: '', href: '' };
    this.$set(
      this.base,
      'items',
      this.base.items ? [...this.base.items, { label, href }] : [{ label, href }],
    );

    // Translations also need to be added.
    Object.keys(this.base.i18n.translations).forEach((lang) => {
      this.$set(
        this.base.i18n.translations[lang],
        'items',
        this.base.i18n.translations[lang].items
          ? [...this.base.i18n.translations[lang].items, { label, href }]
          : [{ label, href }],
      );
    });
  }

  public handleRemoveNavigationLink(i): void {
    this.$set(
      this.base,
      'items',
      this.base.items.filter((_, index) => index !== i),
    );

    // Translations also need to be removed.
    Object.keys(this.base.i18n.translations).forEach((lang) => {
      this.$set(
        this.base.i18n.translations[lang],
        'items',
        this.base.i18n.translations[lang].items.filter((_, index) => index !== i),
      );
    });
  }
}
