
import { Vue, Component, Prop } from 'vue-property-decorator';
import MultiLanguageInput from '@/components/shared/MultiLanguageInput.vue';
import { Widget } from '@govflanders/mbp-admin-panel-shared';

@Component({
  components: { MultiLanguageInput },
})
export default class Alert extends Vue {
  @Prop({ type: Object, required: true })
  public value!: Widget;
  public alertTypes = ['warning', 'success', 'error'];
}
