var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('vl-title',{attrs:{"tag-name":"h3"}},[_vm._v(" "+_vm._s(_vm.$t('widgets.configform.siteInfoSectionTitle'))+" ")]),_c('vl-grid',{attrs:{"mod-stacked":""}},[_c('vl-form-column',{attrs:{"width":"12"}},[_c('MultiLanguageInput',{attrs:{"id":"footer-siteinfo-title","base":_vm.base,"value-path":['siteInfo', 'title'],"label":_vm.$t('widgets.configform.siteInfoTitle'),"prefilled-values":{
          fr: '... est un site web officiel de La Flandre',
          en: '... is an official website of the Flemish government',
          de: '... ist eine offizielle Website der flämischen Behörden',
        }}})],1),_c('vl-form-column',{attrs:{"width":"6"}},[_c('MultiLanguageInput',{attrs:{"id":"footer-siteinfo-label","base":_vm.base,"value-path":['siteInfo', 'subTitleItems', _vm.textItemIndex, 'label'],"label":"Uitgegeven door","prefilled-values":{
          fr: 'publié par ...',
          en: 'published by ...',
          de: 'herausgegeben von ...',
        }}})],1),_vm._l((_vm.value.subTitleItems),function(item,i){return _c('vl-form-column',{key:`subTitleItem-${i}`,attrs:{"width":"12"}},[(_vm.linkItem && item.type === 'link')?_c('vl-grid',{staticClass:"subtitleItem"},[_c('vl-form-column',{attrs:{"width":"5"}},[_c('MultiLanguageInput',{attrs:{"id":`footer-siteinfo-subtitle-${i}-label`,"base":_vm.base,"value-path":['siteInfo', 'subTitleItems', i, 'label'],"label":_vm.$t('widgets.configform.siteInfoIssuingParty')}})],1),_c('vl-form-column',{attrs:{"width":"6"}},[_c('MultiLanguageInput',{attrs:{"id":`footer-siteinfo-subtitle-${i}-url`,"base":_vm.base,"value-path":['siteInfo', 'subTitleItems', i, 'href'],"label":_vm.$t('widgets.configform.siteInfoIssuingPartyUrlLabel'),"rules":"required|url"}})],1),_c('vl-form-column',{attrs:{"width":"1"}},[_c('vl-button',{attrs:{"icon":"bin","mod-icon":"","mod-tertiary":"","mod-error":""},on:{"click":function($event){return _vm.handleRemoveIssuingEntity(item)}}})],1)],1):_vm._e()],1)}),_c('vl-form-column',{attrs:{"width":"12"}},[_c('vl-button',{on:{"click":_vm.handleAddIssuingEntity}},[_vm._v(" "+_vm._s(_vm.$t('widgets.configform.siteInfoIssuingPartyAdd'))+" ")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }