
import { Component, Prop } from 'vue-property-decorator';
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderAction from '@/components/shared/PageHeaderAction.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';
import JsonEditor from '@/components/shared/forms/JsonEditor.vue';
import WidgetMetaData from '@/components/widgets/WidgetMetaData.vue';
import { ImportedWidget, Widget } from '@govflanders/mbp-admin-panel-shared';
import { getHeaderExtensionTemplate, getWidgetConfigSchemaAndTemplate } from '@/utility/widgets';
import WidgetFormHeader from '@/components/widgets/WidgetFormHeader.vue';
import WidgetFormFooter from '@/components/widgets/WidgetFormFooter.vue';
import { merge, cloneDeep, mergeWith, isArray } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Getter } from 'vuex-class';
import ValidationNotification from '@/components/shared/ValidationNotification.vue';
import { mixins } from 'vue-class-component';
import PendingChangesGuard from '@/utility/forms/PendingChangesGuard.vue';
import * as brandingMigrationHelper from './branding-migration-helper';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: {
    Anchor,
    PageHeader,
    PageHeaderAction,
    PageHeaderNavigationAction,
    JsonEditor,
    WidgetMetaData,
    WidgetFormHeader,
    WidgetFormFooter,
    ValidationNotification,
    ValidationObserver,
  },
})
export default class WidgetAdd extends mixins(PendingChangesGuard) {
  /**
   * Holds the widget type.
   *
   * @var {string}
   */
  @Prop({
    type: String,
    required: true,
  })
  public type: string;

  @Getter('widgetImport/results')
  private results: ImportedWidget[] | null;

  @Getter('app/env')
  public env: string;

  public value: Widget | null = null;
  public initialValue: Widget | null = null;
  public schema: object | null = null;
  public isLoading: boolean;

  private _config: { schema: object; value: Widget };
  private importedWidgetConfig: ImportedWidget;

  public created() {
    this.isLoading = true;

    this._config = getWidgetConfigSchemaAndTemplate(this.type, this.$store.getters['app/env']);
    this.schema = this._config.schema;

    const selectedAppId = this.$route.query.selectedAppID;
    if (selectedAppId) {
      this.importedWidgetConfig = this.results.find(
        (el) =>
          el.widgetConfig &&
          this.type.includes(el.widgetConfig.pluginTypeId) &&
          el.importedAppId === selectedAppId,
      );

      const mergedValue = cloneDeep(this._config.value);

      mergeWith(mergedValue, this.importedWidgetConfig?.widgetConfig, (obj, src) => {
        if (isArray(obj)) {
          return src;
        }
      });

      if (mergedValue.extensions) {
        const mergedExtensions = [];
        for (const extension of mergedValue.extensions) {
          const mergedExtension = cloneDeep(
            this._config.value.extensions.find((el) => el.pluginModule === extension.pluginModule),
          );

          if (mergedExtension) {
            merge(mergedExtension, extension);
            mergedExtensions.push(mergedExtension);
          } else {
            mergedExtensions.push(extension);
          }
        }
        mergedValue.extensions = mergedExtensions;
      }

      if (
        mergedValue.extensions &&
        !mergedValue.extensions.find((el) => el.pluginTypeId === 'contact')
      ) {
        mergedValue.extensions.push(getHeaderExtensionTemplate(this.env, 'contact'));
      }

      if (mergedValue.branding && !mergedValue.brandingId) {
        delete mergedValue.brandingId;
      }

      this._config.value = mergedValue;
    }

    this._config.value.id = uuidv4();

    if (!this._config.value.origins.includes(document.location.origin)) {
      this._config.value.origins.push(document.location.origin);
    }

    this.initialValue = this._config.value;
    this.value = cloneDeep(this._config.value);

    this.isLoading = false;
  }

  public onSave() {
    if (this.value) {
      this.isLoading = true;

      if (this.value.branding && this.value.brandingId) {
        this.value = brandingMigrationHelper.cleanUpOldBrandingConfig(this.value);
      }

      this.$store
        .dispatch(
          this.importedWidgetConfig ? 'widget/createImported' : 'widget/create',
          this.importedWidgetConfig
            ? { ...this.importedWidgetConfig, widgetConfig: this.value }
            : this.value,
        )
        .then((createdValue) => {
          // TODO: Should provide translation support.
          this.initialValue = createdValue;
          this.value = createdValue;
          this.$toast.success('Widget succesvol aangemaakt');
          // Redirect to the widget edit page for our newly create widget config.
          this.$router.push({ name: 'widgets.edit', params: { id: createdValue.id } });
        })
        .catch(() => {
          // TODO: Should provide translation support.
          this.$toast.error('Kon widget niet aanmaken');
          this.isLoading = false;
        });
    }
  }

  public onRevert() {
    this.value = cloneDeep(this._config.value);
    // TODO: Should provide translation support.
    this.$toast.success('Widget was reverted');
  }

  public migrateBranding() {
    brandingMigrationHelper
      .migrateBranding(this.value, this.$store, (key) => this.$t(key), this.$toast)
      .then((response) => (this.value = response));
  }
}
